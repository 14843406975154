import * as React from "react"

import { cva, type VariantProps } from "./cva.ts"

/** Group used to align and space buttons consistently across the platform. */
export const ButtonGroup = React.forwardRef<
  HTMLSpanElement,
  VariantProps<typeof classForButtonGroup> & React.ComponentPropsWithoutRef<"span">
>(({ className, justify, ...props }, ref) => {
  return (
    <span
      data-ui="button-group"
      className={classForButtonGroup({ className, justify })}
      ref={ref}
      {...props}
    />
  )
})

ButtonGroup.displayName = "ButtonGroup"

export const classForButtonGroup = cva(["flex flex-wrap gap-2"], {
  variants: {
    justify: {
      end: "justify-end",
      start: "justify-start",
      between: "justify-between",
    },
  },
  defaultVariants: {
    justify: "end",
  },
})
